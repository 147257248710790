import React from 'react';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";

const TermConditionsPFD: React.FC = () => {
    return (
        <>
            <Navbar />
            <div className="w-full  h-svh">
                <iframe
                    src={`${process.env.PUBLIC_URL}/terms-and-conditions.pdf`}
                    style={{ width: '100%', height: '100%' }}
                />
            </div>
            <Footer />
        </>
    );
};

export default TermConditionsPFD;
